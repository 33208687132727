import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
                  {/*<li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/donde-has-estado/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/Por-Onde-Andaste/">PT</a>
                      </li>
                      <li>
                        <a href="/it/Ma-Dove-Sei-Stata/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/Where-Have-You-Been/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <div className="page-heritage__intro"></div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div
                  id="blockStyle4076Content127"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4076" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "30px 20px 0px 20px"
                            }}
                          >
                            <h1
                              style={{
                                color: "#f68e27",
                                "-webkit-text-align": "center",
                                "text-align": "center",
                                "line-height": "130%"
                              }}
                            >
                              ΠΙΣΩ ΑΠΟ ΕΝΑ ΣΑΓΗΝΕΥΤΙΚΟ ΜΑΥΡΙΣΜΑ ΥΠΑΡΧΟΥΝ ΠΟΛΛΕΣ
                              ΙΣΤΟΡΙΕΣ ΠΟΥ ΘΕΣ ΝΑ ΜΟΙΡΑΣΤΕΙΣ!
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3982" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4152Content120"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4152" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      ΨΑΧΝΕΙΣ ΕΝΑ ΕΚΘΑΜΒΩΤΙΚΟ
                                                      ΜΑΥΡΙΣΜΑ; ΒΕΒΑΙΩΣΟΥ ΟΤΙ
                                                      ΕΙΣΑΙ ΠΡΟΣΤΑΤΕΥΜΕΝΗ!
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      Για πολύ καιρό
                                                      κυκλοφορούσε ένας μύθος
                                                      σύμφωνα με τον οποίο για
                                                      να επιτύχουμε ένα όμορφο
                                                      και γρήγορο μαύρισμα δεν
                                                      είναι απαραίτητο να
                                                      εφαρμόσουμε φωτοπροστασία
                                                      στο δέρμα. Λάθος! Η άμεση
                                                      ηλιακή ακτινοβολία είναι
                                                      επικίνδυνη για την υγεία
                                                      της επιδερμίδας, και
                                                      προκαλεί ερεθισμούς. Η
                                                      υπεριώδης ακτινοβολία
                                                      UVA/UVB ευθύνεται για το
                                                      άμεσο αλλά παροδικό
                                                      μαύρισμα της επιδερμίδας,
                                                      ταυτόχρονα όμως μπορεί να
                                                      συμβάλλει στη φωτογήρανση
                                                      ή τον καρκίνο του
                                                      δέρματος, για το λόγο αυτό
                                                      είναι πολύ σημαντική η
                                                      προστασία.
                                                      <br />
                                                      <br />
                                                      Το δέρμα μας έχει
                                                      διαφορετικούς μηχανισμούς
                                                      άμυνας κατά της ηλιακής
                                                      ακτινοβολίας, με κυριότερο
                                                      την παραγωγή μελανίνης.
                                                      Αυτή η χρωστική ουσία
                                                      είναι υπεύθυνη για το
                                                      χρώμα του δέρματος, των
                                                      μαλλιών και της ίριδας των
                                                      ματιών μας, μεταξύ άλλων,
                                                      και έχει την ικανότητα να
                                                      φιλτράρει με φυσικό τρόπο
                                                      τις επιβλαβείς ηλιακές
                                                      ακτίνες.
                                                      <br />
                                                      <br />
                                                      Υπάρχουν δύο τύποι
                                                      μελανίνης, η φαιομελανίνη
                                                      (από κόκκινο ως κίτρινο)
                                                      και η ευμελανίνη (από
                                                      σκούρο καφέ ως μαύρο
                                                      χρώμα). Ο τύπος της
                                                      μελανίνης και η ποσότητά
                                                      της σε κάθε άτομο
                                                      καθορίζονται από τα
                                                      γονίδια. Η επιδερμίδα
                                                      μαυρίζει ως αποτέλεσμα της
                                                      έκθεσης στις ακτίνες του
                                                      ηλίου (UVA / UVB) και αυτό
                                                      συνιστά μια αμυντική
                                                      αντίδραση του δέρματος που
                                                      εμποδίζει την καταστροφή ή
                                                      βλάβη των κυτταρικών
                                                      πυρήνων.
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3983" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4071Content121"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4071" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                              dir="rtl"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "100%",
                                                "max-height": "500px",
                                                "margin-bottom": "-70px"
                                              }}
                                              src="/assets/images/tanprotect-aftersun_rgb.jpg"
                                              alt
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      ΣΑΓΗΝΕΥΤΙΚΟ ΜΑΥΡΙΣΜΑ &amp;
                                                      ΠΡΟΣΤΑΣΙΑ, ΠΙΟ ΓΡΗΓΟΡΑ ΑΠΟ
                                                      ΠΟΤΕ! ΝΕΟ PIZ BUIN TAN
                                                      &amp; PROTECT
                                                      <span
                                                        style={{
                                                          "font-size": "15px",
                                                          "vertical-align":
                                                            "super",
                                                          "line-height": "0"
                                                        }}
                                                      >
                                                        ®
                                                      </span>{" "}
                                                      TAN INTENSIFYING SUN SPRAY
                                                      SPF 30
                                                      <span
                                                        style={{
                                                          "font-size": "15px",
                                                          "vertical-align":
                                                            "super",
                                                          "line-height": "0"
                                                        }}
                                                      >
                                                        ®
                                                      </span>
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      ΟΦΕΛΗ
                                                      <br />
                                                      PIZ BUIN® TAN &amp;
                                                      PROTECT® TAN INTENSIFYING
                                                      SUN SPRAY SPF 30 <br />
                                                      Υψηλή Προστασία
                                                      <br />
                                                      Με Illumitone™ που
                                                      επιταχύνει τη φυσική
                                                      διαδικασία μαυρίσματος*.
                                                      Προστατεύει άμεσα.
                                                      <br />
                                                      Απορροφάται αμέσως.
                                                      Ανθεκτικό στο νερό.
                                                      Σημείωση: Δεν περιέχει
                                                      ουσίες αυτομαυρίσματος.
                                                      <br />
                                                      ΔΙΑΘΕΣΙΜΟ ΣΕ SPF 30
                                                      <br />
                                                      *Δοκιμές ex vivo
                                                      <br />
                                                      ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ: Εφαρμόστε
                                                      ομοιόμορφα μια γενναιόδωρη
                                                      ποσότητα πριν την έκθεση
                                                      στον ήλιο. Μειωμένη
                                                      ποσότητα μειώνει σημαντικά
                                                      το επίπεδο προστασίας.
                                                      Επαναλάβετε συχνά την
                                                      εφαρμογή, ειδικά μετά από
                                                      εφίδρωση, κολύμπι και
                                                      σκούπισμα με πετσέτα.
                                                      Aποφύγετε τον μεσημεριανό
                                                      ήλιο και την παρατεταμένη
                                                      παραμονή, ακόμα και με την
                                                      χρήση αντηλιακού. Κρατήστε
                                                      βρέφη και παιδιά μακριά
                                                      από την απευθείας έκθεση
                                                      στην άμεση ακτινοβολία του
                                                      ήλιου. Για εφαρμογή στο
                                                      πρόσωπο μην ψεκάζετε
                                                      απευθείας αλλά εφαρμόστε
                                                      πρώτα στις παλάμες και
                                                      επαλείψτε. Προσοχή για
                                                      τυχόν υπολείμματα του
                                                      προϊόντος στο πάτωμα,
                                                      καθώς ενδέχεται να
                                                      προκαλέσουν ολισθηρότητα.
                                                      <br />
                                                      <br />
                                                      <a
                                                        href="/gr/our-products/gallery/"
                                                        target="_blank"
                                                        style={{
                                                          "-webkit-text-decoration":
                                                            "none",
                                                          "text-decoration":
                                                            "none"
                                                        }}
                                                      >
                                                        Ανακαλύψτε όλα τα
                                                        προϊόντα της Piz Buin
                                                      </a>
                                                      <br />
                                                      <br />
                                                      Και μην ξεχάσετε να
                                                      ενυδατώσετε το δέρμα σας
                                                      μετά την ηλιοθεραπεία με
                                                      το ΝΕΟ After Sun Mist
                                                      Spray με υαλουρονικό οξύ
                                                      για μαύρισμα μεγαλύτερης
                                                      διάρκειας.
                                                      <br />
                                                      ΟΦΕΛΗ
                                                      <br />
                                                      Το σπρέι PIZ BUIN® After
                                                      Sun Instant Relief Mist
                                                      Spray για μετά τον ήλιο,
                                                      καταπραΰνει άμεσα κι
                                                      αναζωογονεί την επιδερμίδα
                                                      σας. Χάρη στη μοναδική του
                                                      σύνθεση με υαλουρονικό οξύ
                                                      και γλυκερίνη, προσφέρει
                                                      48ωρη ενυδάτωση που
                                                      αποκαθιστά την ισορροπία
                                                      της φυσικής υγρασίας του
                                                      δέρματος και προλαμβάνει
                                                      το ξεφλούδισμα, για
                                                      μαύρισμα που διαρκεί
                                                      περισσότερο. Mε σύστημα
                                                      ψεκασμού πολλαπλών θέσεων
                                                      εφαρμόζεται εύκολα ενώ
                                                      χάρη στην ελαφριά μη
                                                      λιπαρή του σύνθεση,
                                                      απορροφάται γρήγορα από
                                                      την επιδερμίδα σας και δεν
                                                      κολλάει.
                                                      <br />
                                                      <br />
                                                      ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ
                                                      <br />
                                                      Για 48ωρη ενυδάτωση,
                                                      επαναλάβετε την εφαρμογή
                                                      μετά το μπάνιο σε στεγνό
                                                      δέρμα.
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3984" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4013Content122"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4013" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%",
                                      "padding-bottom": "6%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "50%"
                                              }}
                                              src="/assets/images/SUN_PICTURES_left.jpg"
                                              alt
                                              width="100%"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
            </div>
    );
  }
}

export default Page;
